export default {
	areWelcome: "are welcome.",
	by: "by",
	contributions: "Contributions",
	like: "like",
	love: "love",
	madeWith: "Made with",
	pageDescription:
		"All the socials and the best bachata classes and salsa classes near you. Stop searching through multiple groups and find today’s SBK social here with just one click!",
	pageKeywords:
		"Bachata classes, Salsa classes, Dance schools near me, Dance classes near me, Bachata classes near me, Dance academy near me, Dance class near me, Salsa parties, Bachata parties",
	pageTitle:
		"Bachata and Salsa Socials and Classes Near You | Find SBK Events Today",
	salsaBachataKizomba: "Salsa Bachata Kizomba",
	salsaBachataKizombaSocials: "Salsa Bachata Kizomba Socials",
	socials: "Socials",
	thisIsAnOpenSourceProject: "This is an open-source project.",
	eventName: "Event name",
	eventInformationUrl: "Event information link",
	date: "Date",
	startTime: "Start time",
	endTime: "End time",
	location: "Location",
	locationGoogleMapsUrl: "Google Maps link",
	organizer: "Organizer",
	salsaPercentage: "Salsa percentage",
	bachataPercentage: "Bachata percentage",
	kizombaPercentage: "Kizomba percentage",
	createEvent: "Create event",
	wrongValueType: "Wrong value type",
	valueMustBeBetween: "Value must be between {{min}} and {{max}}",
	wrongUrl: "Wrong link",
	mandatoryField: "Mandatory field",
	socialMediaPosterEtc: "Social media poster, WhatsApp group, etc.",
	totalMustBe100: "Total must be 100",
	close: "Close",
	eventCreated: "Event created!",
	publishEvent: "Publish event",
	eventSuccessfullyCreatedInfo:
		"Thanks for submitting the event! We typically review submissions within two hours and guarantee a response within 24 hours. You’ll see your event online soon!",
	allTheEventsAndClasses:
		"Do you know of an event that isn't listed here? [Add it](/events/create) even if you're not the organizer!\nHelp us all know where we can dance today!",
	title: "Title",
	content: "Content",
	save: "Save",
	blog: "Blog",
	posts: "Posts",
	eventsInValencia: "Events in Valencia",
	once: "One time",
	weekly: "Weekly",
	frequency: "Frequency",
	openInNewTab: "Open in new tab",
};
